import React, { useEffect } from 'react';
import { MiddleContainer } from '../../assets/middle-container';
import { FooterPage } from '../footer/footer';
import { HeaderPage } from '../header/header';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, Stack, Text } from '@mantine/core';
import {
  maxWidthTablet,
  monthNamesEN,
  monthNamesRO,
  RoutesOptions,
} from '../../constants';
import { useMediaQuery } from '@mantine/hooks';
import { CardArticle } from './card-article';
import { CardMostRecentArticle } from './card-most-recent-article';

export default function BlogPage() {
  const language = localStorage.getItem('i18nextLng');
  const isTablet = useMediaQuery(maxWidthTablet);
  const { t } = useTranslation();

  useEffect(() => {
    if (language) i18next.changeLanguage(language);
  }, [language]);

  const blogArticles = [
    {
      title: t('title_article_1'),
      content: t('content_article_1'),
      photo: '/articol-1.png',
      date: t('date_article_1'),
      link: RoutesOptions.POST_1,
    },
    {
      title: t('title_article_2'),
      content: t('content_article_2'),
      photo: '/articol-2.png',
      date: t('date_article_2'),
      link: RoutesOptions.POST_2,
    },
    {
      title: t('title_article_3'),
      content: t('content_article_3'),
      photo: '/articol-3.jpg',
      date: t('date_article_3'),
      link: RoutesOptions.POST_3,
    },
    {
      title: t('title_article_4'),
      content: t('content_article_4'),
      photo: '/articol-4.jpg',
      date: t('date_article_4'),
      link: RoutesOptions.POST_4,
    },
    {
      title: t('title_article_5'),
      content: t('content_article_5'),
      photo: '/articol-5.jpg',
      date: t('date_article_5'),
      link: RoutesOptions.POST_5,
    },
    {
      title: t('title_article_6'),
      content: t('content_article_6'),
      photo: '/articol-6.png',
      date: t('date_article_6'),
      link: RoutesOptions.POST_6,
    },
    {
      title: t('title_article_7'),
      content: t('content_article_7'),
      photo: '/articol-7.png',
      date: t('date_article_7'),
      link: RoutesOptions.POST_7,
    },
  ];
  const getMostRecentArticle = (articles: any) => {
    return articles.reduce((latest: any, current: any) => {
      const parseDate = (dateStr: any) => {
        const [day, month, year] = dateStr.split(' ');
        return new Date(
          year,
          language === 'en'
            ? monthNamesEN.indexOf(month)
            : monthNamesRO.indexOf(month),
          day
        );
      };

      const latestDate = parseDate(latest.date);
      const currentDate = parseDate(current.date);
      return currentDate > latestDate ? current : latest;
    });
  };

  const mostRecentArticle = getMostRecentArticle(blogArticles);

  return (
    <>
      <HeaderPage />
      <MiddleContainer>
        <Stack align={'center'} spacing={0} mb='md'>
          <Text
            size={isTablet ? 'md' : 'xs'}
            ff='Barlow'
            fw={400}
            c='#D3D3D3'
            transform='uppercase'>
            {t('blog_title')}
          </Text>
          <Text size={'xl'} ff='Barlow' mb='md' fw={600} c='#b99350'>
            {t('blog_subtitle')}
          </Text>
          <Text
            size={'18px'}
            ff='Barlow'
            fw={400}
            c='#D3D3D3'
            w={isTablet ? '80vw' : '40vw'}>
            {t('blog_describe')}
          </Text>
        </Stack>
        <CardMostRecentArticle mostRecentArticle={mostRecentArticle} />
        <Stack my='lg'>
          <Text
            align='left'
            size={isTablet ? 'md' : 'xs'}
            ff='Barlow'
            mt={'md'}
            fw={400}
            c='#b99350'
            transform='uppercase'>
            {t('blog_title_see_all_articles')}
          </Text>
          <SimpleGrid
            cols={2}
            breakpoints={[
              { maxWidth: 'xs', cols: 1, spacing: 'sm' },
              { minWidth: 'lg', cols: 3, spacing: 'sm' },
            ]}>
            {blogArticles.map((article, index) => (
              <CardArticle key={index} article={article} />
            ))}
          </SimpleGrid>
        </Stack>
      </MiddleContainer>
      <FooterPage />
    </>
  );
}
