import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDectector from 'i18next-browser-languagedetector';

i18next
  .use(initReactI18next)
  .use(LanguageDectector)
  .init({
    resources: {
      en: {
        translation: {
          navbar_btn_home: 'HOME',
          navbar_btn_who_is: 'ABOUT CAMELIA PAU',
          navbar_btn_services: 'CAMELIA PAU`S AREA OF EXPERTISE',
          navbar_btn_blog: 'BLOG',
          title: 'QUALITY LEGAL SERVICES ',
          subtitle: 'LAW PRACTICE',
          characteristics_card_efficiency: 'Efficiency',
          characteristics_card_efficiency_content:
            "We deliver legal services with efficiency and productivity. Our clients' needs and interests are our priority, and legal solutions are provided promptly once they have been identified.",
          characteristics_card_professionalism: 'Professionalism',
          characteristics_card_professionalism_content:
            'We approach each case based on an exhaustive analysis of the legal situations, analysing the case from various angles, so that we are able to foresee any possible legal consequences.',
          characteristics_card_trust: 'Trust',
          characteristics_card_trust_content:
            'Any professional relationship requires honesty and transparency to build mutual trust. These aspects are essential for the success and stability of professional relationships.',
          title_services_section: 'CAMELIA PAU`S AREA OF EXPERTISE',
          content_services_section:
            "The senior lawyer's portfolio of competences within PAU CAMELIA law practice includes multiple distinct areas of practice and expertise, with a strong focus on the area of public procurement, but also in the area of labour relations and human resources, having aquired additional skills in these areas. Thus, in 2018, she also became a public procurement expert, and in 2023, she deepened her knowledge in the area of human resources, also graduating from a human resources referent course organized by the Timiș County Territorial Labour Inspectorate.",
          content_services_section_paragraph_1:
            "The senior lawyer's portfolio of competences within PAU CAMELIA law practice includes multiple distinct areas of practice and expertise, with a strong focus on the area of public procurement, but also in the area of labour relations and human resources, having aquired additional skills in these areas. Thus, in 2018, she also became a public procurement expert, and in 2023, she deepened her knowledge in the area of human resources, also graduating from a human resources referent course organized by the Timiș County Territorial Labour Inspectorate.",
          content_services_section_paragraph_2:
            'Pau Camelia distinguishes herself through her continuous professional improvement, through her specialised knowledge and relevant skills in the areas of labour law and public procurement, bringing an expert perspective in these areas and providing  essential support to her clients in all complex aspects related to these areas.',
          button_learn_more: '  Learn more',
          card_coporate_law_content:
            'Corporate law is related to rules such as governance structure, shareholder rights, corporate management responsibilities, operation and management of corporations. It is essential for ensuring an effective and fair corporate governance.',
          card_coporate_law_title: 'Corporate law',
          card_commercial_law_content:
            'Trade law focuses on the rules and regulations governing business activities.  It is crucial for maintaining a fair and well-functioning business environment, by protecting the interests of both businesses and consumers.',
          card_commercial_law_title: 'Trade law',
          card_labour_law_content:
            'Labour law is that branch of the legal system which regulates the field of employment relationships. Employment relationships are defined as those relationships established when an employment contract is concluded, performed, modified or terminated.',
          card_labour_law_title: 'Labour law',
          card_public_procurement_law_content:
            'The sum of all processes of planning, setting priorities, organisation, publicity and procedures, to perform purchases by organisations that are totally or partially funded by public budgets (European, national budgets, international donors).',
          card_public_procurement_law_title: 'Public procurement',
          card_tax_law_content:
            'Tax law, as a branch of public law, is made up of the totality of regulatory documents governing tax legal relations that arise, change and terminate in the process of collecting taxes from taxpayers.',
          card_tax_law_title: 'Tax law',
          card_civil_law_content:
            'Civil law is that branch of law regulating social, property and non-property relationships established between natural persons and/or legal entities having the same equal legal status, even when one of the parties is the State (as a private person and not as a public authority).',
          card_civil_law_title: 'Civil law',
          card_administrative_litigation_content:
            'The activity of settlement, with the power of legal truth, by competent administrative courts, according to the law of legal conflicts where at least one of the parties is a public administrative service, and the legal conflict arose by adopting or issuing an administrative document by the authority or by denying to settle a claim related to a right recognised by law.',
          card_administrative_litigation_title:
            'Contentious administrative matters',
          card_tort_law_content:
            'Contravention law can be defined as a set of legal rules of public law, regulating social relations regarding the contravention and misdemeanour, which are intended to legally protect the social values established by contravention law.',
          card_tort_law_title: 'Contravention law',
          title_career_section: 'ABOUT CAMELIA PAU',
          content_career_section_1:
            "Pau Camelia - Sabina - head attorney within Pau Camelia - Law practice - graduated from the Faculty of Law of West University of Timișoara in 2005 and then followed the Master's degree at the West University of Timișoara - in Community Law, which she graduated in 2007.",
          content_career_section_2:
            "She started her professional activity in the area of law since her Master's degree studies, i.e. in 2006, as a legal consultant within a law practice.",
          content_career_section_3:
            'She took and passed the entrance exam as a lawyer in 2009 and has been a member of the Timiș County Bar since 2009.',

          content_career_section_4:
            ' years of activity as a lawyer, she has practiced and specialized in business law, dealing intensively with supporting and providing with specialized legal advice, mainly to legal entities (companies, public institutions, associations, foundations) since their establishment, but also during their activity, sometimes until their cancellation and liquidation.',
          button_read_more: 'Read more',
          title_contact_section: 'PAU CAMELIA LAW PRACTICE',
          subtitle_contact_section: 'CONTACT',
          phone: 'PHONE',
          mail_adress: 'E-MAIL ADDRESS',
          adress: 'ADDRESS',
          subtitle_form_contact: 'LAW PRACTICE',
          placeholder_form_name: 'Name',
          placeholder_form_email: 'E-mail',
          placeholder_form_phone: 'Phone number',
          placeholder_form_message: 'Message',
          button_form_contact: 'Send message',
          error_message_name: 'The message should contain at least 5 letters.',
          error_message_phone: 'The phone number is not correct.',
          error_message_email: 'The e-mail address is not correct.',
          error_message: 'The message should contain at least 10 letters.',
          succes_message_emailJs: 'The message was successfully sent.',
          error_message_emailJs: 'An error has occurred, please try again.',
          title_testimonial_section: '  CUSTOMER FEEDBACK IS IMPORTANT TO US!',
          review_elstad:
            'I have been working successfully and for a long time with Pau Camelia Law Practice. Every time the recovery solutions have had a loophole, Mrs Pau Camelia has managed to exploit it to our advantage, through legal means. I highly recommend this law practice for the debt recovery and the drafting of contracts that have protected us as far as possible from undesirable situations. Thank you for the legal support given with great professionalism.',
          review_eei:
            'I highly recommend the services of the law practice! For each situation, Mrs Camelia Pau correctly assessed the way forward; I was always surprised by how clearly she presented our arguments; she treated each work with utmost care so that we were always successful! The law practice provides us with the necessary legal services in the current activity of the company, but also in the area of public procurement for our successful participation in public tenders. Every time the services provided by the law practice have brought us the best possible result.',
          review_trigo:
            'Pau Camelia Law Practice has become for us a reliable partner with which we have been collaborating very well for several years. The law practice successfully represented us before the Court of Arbitration, in disputes with foreign partners, but also before the Romanian courts, in disputes with domestic partners and provides us with legal support and necessary advice in the current activity of the company, as well as legislative information in order to be up to date with all legislative amendments that occur and have an impact on the activity of our company.',
          review_plan_net:
            'We express our gratitude regarding the legal support provided by Pau Camelia Law Practice because, every time, our requests for legal services for any corporate changes to the Trade Register, for the drawing up of contracts and any other legal documents and for legal advice, have been promptly and professionally honoured, and the financial arrangements regarding the agreed fees have always been complied with.',
          review_papasam:
            'We would like to thank Pau Camelia Law Practice for being with us in our activity in the HoReCa industry, for being with us during the difficult periods we faced during the Covid pandemic when commercial negotiations with our partners were necessary, negotiations where we relied on the good advice provided by our lawyer. Pau Camelia - Law Practice also supported us with the operations of amendment and extension of the trademarks we own and successfully represented us before OSIM (Romanian State Office for Inventions and Trademarks).',
          review_emiliaszuszmann:
            'We have been working together with the law practice Camelia Pau for over a decade. This is because it is a prompt collaboration that has always responded quickly to the needs of the education association I manage. Laws and rules have changed often during the years I have been leading the association, but I have always found the reassurance of an informed and researched consulting when needed. I highly recommend Camelia Pau Law Practice for those who want a long-term collaboration based on a great deal of fair play, transparency in communication and professionalism.',
          review_pensiuneajojo:
            'I strongly recommend the law practice services because I experienced professionalism, reliability and promptness every time I called. From company establishment to consulting and representation, I have experienced every time a loyal and dedicated team, thank you for your support and for the dedication you put in solving all situations!',
          review_swiss_house:
            'With Camelia Pau Law Practice we have had an fabulous collaboration for over 3 years. The law practice provides us with very high quality legal services and advice for our business, legal services regarding the company representation in various disputes, as well as legal assistance in connection with trade contracts.',
          review_consorhogar:
            'Whenever we have faced or are facing any legal problem, we confidently appeal to Pau Camelia Law Practice because we are sure that the interests of our company are well defended in each individual case.  Pau Camelia Law Practice has successfully defended us in disputes we had with the authorities in the area of consumer protection and not only; at the same time, it constantly provides us with the legal advice we need in our construction and real estate development activity.',
          review_boobielove:
            'Pau Camelia Law Practice has been with us from the very beginning since we set up the Moving On Breast Cancer Association, helped us with the association establishment and then supported us in our association activity. We appreciate very much that, besides the legal support we receive every time we need legal services in the association activity, we feel that we also have human support and empathy from Mrs Pau Camelia.',
          footer_title_services: 'PRACTICE AREAS',
          footer_title_about: 'ABOUT',
          footer_title_blog: 'BLOG',
          title_timeline_career_page: 'HOW IT ALL BEGAN?',
          content_timeline_1:
            ' She was admitted to the Faculty of Law at the West University of Timișoara.',
          content_timeline_2:
            " She graduated from the Faculty of Law with a degree in Law and started her Master's studies in Community Law at the West University of Timișoara.",
          content_timeline_3:
            ' She worked as a legal consultant within a law practice.',
          content_timeline_4:
            " She completed her postgraduate studies and obtained a Master's degree in Community Law at the West University of Timișoara.",
          content_timeline_5:
            ' She took and passed the entrance exam as a lawyer, being a registered lawyer of the Timiș County Bar.',
          content_timeline_6:
            ' She is currently following her calling, bringing to society a bright future marked by justice and fairness.',
          title_career_page: 'EXPERIENCE GAINED',
          paragraph_career_page_1_1: ' During her',
          paragraph_career_page_1_2:
            'years of activity as a lawyer, she has practiced and specialized in business law, dealing intensively with supporting and providing with specialised legal advice, mainly to legal entities (companies, public institutions, associations, foundations) since their establishment, but also during their activity, sometimes until their cancellation and liquidation.',
          paragraph_career_page_2:
            " She also acts as a public procurement expert, being specialised in the area of public procurement and providing with the legal advice required to participate in public tenders, as well as legal representation before the contracting authorities and the Romanian Council for the Settlement of Complaints, and as well as before the courts in disputes arose from clients' participation in public tenders.",
          paragraph_career_page_3:
            ' In her professional activity, she strictly follows the oath she took on entering this profession and distinguishes herself as a lawyer: by her professionalism and patience she shows towards the client, but also by her good communication and relationship with the client, by her conscientiousness and responsibility towards the work as a lawyer she practices with passion, but also by the joy she feels in supporting others when she defends their freedoms, rights and legitimate interests.',
          title_circle_container: 'Qualities',
          content_circle_container_1: 'Professionalism',
          content_circle_container_2: 'Patience',
          content_circle_container_3: 'Good communication',
          content_circle_container_4: 'Responsibility',
          service_page_paragraph_1:
            'As head attorney, Pau Camelia, has assisted and represented various clients, legal entities from Romania, as well as many companies from other Member States, in various trade, administrative and jurisdictional proceedings.',
          service_page_paragraph_2:
            'Thus, she has significant and diverse expertise in the area of trade and corporate law, civil law, contentious fiscal and administrative matters, labour law, contravention law, public procurement, being able to provide clients with specialised legal advice at the highest level, legal assistance and judicial and extrajudicial representation in the indicated areas of expertise and other areas as well.',
          service_page_paragraph_3:
            'Therefore, if you face any kind of problems with employees or simply want to implement new procedures in the company or to conclude the collective labour agreement at unit level or you need assistance with the  internal rules and regulations, if you want to participate in public tenders, if you have to recover debts, if you want to conclude or negotiate contracts with the company’s suppliers or customers, if you have to make any kind of statutory amendments within the company, if you want to enter into a new business or exit a business in which you are already involved, if you want to register a trademark, if you have to obtain authorisations, permits or legal documents from the authorities, if you are sued or want to sue someone, as well as in any other legal context that may affect your business activity and not only, Pau Camelia - Law Practice can provide you with the legal advice, support and representation you need to overcome the situation you are facing in the best and most efficient legal way.',
          title_accordion: 'Services provided by PAU CAMELIA Law Practice',

          item_accordion_consultations:
            'Legal consulting and other legal services',
          panel_accordion_consultations_1:
            'We are here to provide you with a full range of legal services, tailored to your needs. Our services include:',
          title_list_item_consultations_1: 'Legal consulting:',
          list_item_consultations_1:
            "We offer legal consulting services through a variety of flexible arrangements to accommodate the preferences of our clients. These options include remote consultations, meetings held at our office, discussions conducted at the client's headquarters, or any other location as specified by the client.",
          title_list_item_consultations_2: 'Other specific legal services:',
          list_item_consultations_2:
            'We cover a wide variety of services specific to the legal profession. These include, but are not limited to: representation before the court, drawing up and analysing documents, dispute settlement, support in negotiations.',
          panel_accordion_consultations_2:
            'All legal services can also be provided remotely, by e-mail, this includes electronically signing the legal assistance contract, as the lawyer Pau Camelia has an extended electronic signature allowing for the electronic submission of various requests and legal actions on behalf and for the account of clients, regardless of their headquarters or residence, be it within Romania or abroad.',
          panel_accordion_consultations_3:
            'Whatever your needs, we are ready to provide you with expertise and support in all legal matters so that you can make informed decisions to defend your rights and protect your interests.',

          item_accordion_support:
            "Client's assistance and/or representation services",
          title_list_item_support_1:
            'Assistance and/or representation before the courts: ',
          list_item_support_1:
            'We offer you representation services before any court in Romania, from courts to courts of appeal, the High Court of Cassation and Justice and the Constitutional Court. We ensure that your rights and interests are strongly and competently defended within judicial processes.',
          title_list_item_support_2:
            'Assistance and/or representation before arbitration courts: ',
          list_item_support_2:
            'We offer representation services before arbitration courts. This includes all aspects of arbitration proceedings, ensuring that you are properly represented before arbitration courts from Romania.',
          title_list_item_support_3:
            'Assistance and/or representation in foreclosure proceedings: ',
          list_item_support_3:
            'We guide you through the process of foreclosure of the enforceable titles, we prepare the foreclosure requests on your behalf and for your account and provide you with the necessary legal assistance and representation, as well as interaction with the  enforcement agents to protect your interests during the foreclosure phase, whether you are a creditor, debtor, third party guarantor or hold any other capacity in the foreclosure proceeding.',
          title_list_item_support_4:
            'Assistance and/or representation before public authorities: ',
          list_item_support_4:
            'We provide with legal representation in dealing with various public authorities and institutions. Our aim is to ensure that your rights are respected and that your interactions with these entities are efficiently and legally handled.',
          title_list_item_support_5:
            'Assistance and/or representation for debt recovery and in the payment order proceeding: ',
          list_item_support_5:
            'We provide with assistance in the debt recovery process, including drawing up and sending payment summonses to  debtors , as well as drawing up payment order claims to efficiently achieve the debt recovery.',
          title_list_item_support_6:
            'Assistance and representation in insolvency proceeding: ',
          list_item_support_6:
            "We support you in insolvency proceedings, including, drawing up the claims to open the proceeding, claims for inclusion in the creditor's group, claims for the director’s liability, appeals against the measures and reports of the official receiver/trustee in bankruptcy, as well as any other insolvency/bankruptcy claims and/or actions.",

          item_accordion_establishment:
            'Establishment and administration of legal entities',
          panel_accordion_establishment_1:
            'We are here to offer you a full range of services related to the establishment and management of legal entities in Timișoara and Timiș County, including Giroc commune. The services we provide cover:',
          title_list_item_establishment_1:
            'Establishment of various types of legal entities: ',
          list_item_establishment_1:
            'We support you in the process of setting up, ensuring that all documents and proceedings comply with legal requirements.',
          title_list_item_establishment_2:
            'Amendments to already registered entities: ',
          list_item_establishment_2:
            'If you need any amendments to the structure or form of any entities that are already registered, we are here to guide you through this process and ensure that all legal aspects are complied with.',
          title_list_item_establishment_3:
            'Opening/closing branches, subsidiaries and work points:',
          list_item_establishment_3:
            'We assist you in opening and/or closing these auxiliary entities, making sure  the entire process takes occurs promptly, effectively and in compliance with legal regulations.',
          title_list_item_establishment_4:
            'Dissolution and liquidation procedures:',
          list_item_establishment_4:
            'If you need to dissolve or liquidate a legal entity, we provide you with guidance and assistance in such processes, ensuring everything is handled legally and properly.',
          title_list_item_establishment_5:
            'Representation before the Romanian Trade Register and Ministry of Justice:',
          list_item_establishment_5:
            'We represent you in dealing with the appropriate authorities, ensuring that all requirements are met and your interests are protected.',
          panel_accordion_establishment_2:
            "We can also ensure, at the lawyer's professional headquarter, the establishment of the temporary registered office for companies that are in the process of being established or that move their registered office to Timișoara or Timiș County (Giroc) or establish an auxiliary registered office in Timișoara or Timiș County (Giroc). This will allow you to start or continue your activities efficiently and in accordance with legal requirements.",

          item_accordion_summations:
            'Drawing up notices, summons, addresses, claims',
          panel_accordion_summations_1:
            'We offer full services for drawing up various types of legal documents, including:',
          title_list_item_summations_1: 'Notices: ',
          list_item_summations_1:
            'We help you to draw up legal notices to communicate important information or requests to any kind of person, natural or legal.',
          title_list_item_summations_2: 'Summonses: ',
          list_item_summations_2:
            'We offer services for drawing up summonses to draw attention to specific obligations or legal actions that must be undertaken by the recipients.',
          title_list_item_summations_3: 'Official letters: ',
          list_item_summations_3:
            'We can assist you in drawing up official letters or written communications, whether it is related to correspondence with authorities, public institutions or other involved parties.',
          title_list_item_summations_4: 'Claims: ',
          list_item_summations_4:
            'We are here to assist you in drawing up various types of claims.',
          panel_accordion_summations_2:
            'Whether you need documents for an official communication, to formulate claims or to request any specific legal actions, we can assist you in drawing up these documents properly and professionally.',

          item_accordion_trademark: 'Full trade mark services',
          title_list_item_trademark_1: 'Registration of trade mark requests: ',
          list_item_trademark_1:
            'We assist you in the process of registering trademark requests, ensuring that the documents are complete and in compliance with legal requirements.',
          title_list_item_trademark_2:
            'Amendments to already registered trademarks: ',
          list_item_trademark_2:
            'If you have already trademarks that are registered and need changes, we are here to guide you through the process of updating them according to your needs.',
          title_list_item_trademark_3:
            'Representation before the Romanian State Office for Inventions and Trademarks (OSIM): ',
          list_item_trademark_3:
            'We represent you in your dealings with OSIM, ensuring that your interests are protected and supported in all aspects related to trade mark requests.',
          title_list_item_trademark_4: 'Trademark oppositions/claims: ',
          list_item_trademark_4:
            'If you are in a position to contend against or submit oppositions regarding the registration of other trademarks, we can represent and support your interests in this process.',

          item_accordion_negotiation:
            'Drawing up, analysing and negotiating contracts',
          panel_accordion_negotiation_1:
            'We offer specialised contract drawing up, analysis and assistance services, as well as support in the contract negotiation process. We are prepared to assist you at all stages related to contracts, including: ',
          title_list_item_negotiation_1: 'Contract drawing up: ',
          list_item_negotiation_1:
            'We can draw up contracts based on your specifications and requirements, ensuring that terms and conditions are clearly expressed and comply with legal requirements.',
          title_list_item_negotiation_2: 'Contract analysis: ',
          list_item_negotiation_2:
            'We carry out detailed analysis of existing contracts to identify key issues, risks and legal implications, ensuring you are well informed before making a decision and contractually well protected.',
          title_list_item_negotiation_3:
            'Support during contract negotiation: ',
          list_item_negotiation_3:
            'We support you in the contract negotiation process, ensuring that your interests are protected and your objectives are met within the negotiations.',
          panel_accordion_negotiation_2:
            'Whether you need a new contract, addenda to existing contracts, analysis of contract proposals from business partners or support in contract negotiations, we are here to provide you with expertise and effective legal solutions.',

          item_accordion_labor_law:
            'Specific services in the area of labour law',
          panel_accordion_labor_law_1:
            'We offer specialised services in the area of labour law for both employers and employees. Here are the ways we can support you: ',
          title_list_item_labor_law_1:
            'Drawing up specific labour law documents: ',
          list_item_labor_law_1:
            'We handle the drawing up of employment contracts, documents amending employment contracts, special clauses of the employment contract, addenda on professional training, documents on disciplinary investigation, necessary documents in case of collective dismissal, any decisions of the employer, as well as other relevant documents to ensure the compliance with the law in labour relations.',
          title_list_item_labor_law_2:
            'Support in the procedure for negotiating, concluding and registering collective employment contracts, as well as drawing up internal rules: ',
          list_item_labor_law_2:
            'We support you in the process of negotiating and drawing up collective employment contracts and internal rules and regulations, ensuring that they comply with legal requirements and reflect the needs of your organisation.',
          title_list_item_labor_law_3:
            'Representation before the Romanian Territorial Labour Inspectorate: ',
          list_item_labor_law_3:
            'We represent you in situations of interaction with the Romanian Territorial Labour Inspectorate, regardless of the type of inspection or control, in a competent manner. Our aim is to ensure that your rights are protected and your interests are defended in accordance with labour law.',
          panel_accordion_labor_law_2:
            'Regardless of your position as an employer or employee, we can provide you with legal advice of the highest quality to find, in each individual case, the best solution to follow according to the provisions of labour law and your interests.',

          item_accordion_public_acquisitions:
            'Full public procurement services',
          panel_accordion_public_acquisitions_1:
            'We offer specialised services in the area of labour law for both employers and employees. Here are the ways we can support you: ',
          title_list_item_public_acquisitions_1:
            'Legal advice and representation in procurement procedures: ',
          list_item_public_acquisitions_1:
            'We provide you with detailed advice during public procurement procedures, ensuring that all legal aspects are complied with. We can also represent you in this process to ensure that your interests are protected.',
          title_list_item_public_acquisitions_2:
            'Preparation of bids for public tenders: ',
          list_item_public_acquisitions_2:
            'We help you prepare competitive and compliant bids to participate in public tenders. We ensure that the documents are complete and comply with the requirements.',
          title_list_item_public_acquisitions_3:
            'Representation before the Romanian National Council for Solving Complaints (CNSC): ',
          list_item_public_acquisitions_3:
            'We represent you before the CNSC in case of complaints related to contract procurement procedures. Our aim is to ensure that your interests are properly defended and that the decision-making process is fair and legal.',
          title_list_item_public_acquisitions_4:
            'Representation before the courts in public procurement disputes: ',
          list_item_public_acquisitions_4:
            'We provide you with representation in disputes that are subject to dispute the award of public procurement contracts, in lawsuits and claims for compensation for damages caused during the procurement procedure, as well as in those concerning the execution, nullity, cancellation, termination or one-sided termination of public procurement contracts.',
          panel_accordion_public_acquisitions_2:
            'Regardless of your position in the public procurement procedure, we offer you specialised services to guide you throughout all stages of the procedure, ensuring that you comply with legal requirements and have the best chance of success in the public procurement of interest to you.',
          published: 'Published',
          blog_title: 'Blog & Justice',
          blog_subtitle: 'Out of love for Justice and People',
          blog_describe:
            'Follow me to uncover real stories and fresh insights from the world of law. Together, we are a voice for justice and respect for every individual!',
          blog_title_most_recent_article: 'Latest post',
          blog_title_see_all_articles: 'View all posts',
          title_article_1:
            'O lecție de umanitate de la CEDO în cauza c. împotriva României',
          content_article_1:
            'Hotărârea în Cauza C. împotriva României din 30.08.2022 nu este doar o importantă bornă de referință în ceea priveste respectarea drepturilor în cazul hărtuirii la locul de muncă, dar este și o lectie de umanitate dată statului român de către judecătorii Curții Europene a Drepturilor Omului – CEDO.',
          date_article_1: '21 june 2024',
          title_article_2:
            'Avocatul - ajutor neprețuit pentru client, clientul - dar neprețuit pentru avocat',
          content_article_2: `Profesia de avocat îl are în centrul atenției pe om, ale cărui drepturi și libertăți le apără. Astfel, la înscrierea în barou, avocatul depune, în cadru solemn, următorul jurământ: "Jur să respect şi să apăr Constituţia şi legile ţării, drepturile şi libertăţile omului şi să exercit profesia de avocat cu cinste şi demnitate. Aşa să-mi ajute Dumnezeu!`,
          date_article_2: '24 june 2024',
          title_article_3: 'Firmele nu mai sunt firme, sunt brand!',
          content_article_3:
            '“Oamenii nu mai sunt oameni, sunt brand” spune un vers din melodia „Opriți planeta” - o melodie difuzată pe toate canalele. Parafrazând acest vers la lumea afacerilor, putem spune că: FIRMELE NU MAI SUNT FIRME, SUNT BRAND! ',
          date_article_3: '1 july 2024',
          title_article_4:
            'Să ne căutăm dreptatea în echilibru este uneori mai bine decât a ne căuta dreptatea în instanță',
          content_article_4:
            'In ultima perioadă de timp auzim vorbindu-se tot mai mult despre importanța echilibrului în viața omului! Tot mai des se propagă ideea de a găsi un echilibru în toate aspectele vieții noastre. ',
          date_article_4: '9 july 2024',
          title_article_5: 'Avocat cu drag de oameni!',
          content_article_5:
            'Pe cei care m-au cunoscut ca și avocat, dar și pe cei care încă nu m-au cunoscut, vă invit să citiți acest articol care este despre mine, despre avocatul Pau Camelia - Sabina, pe scurt, m-aș descrie ca fiind un avocat cu drag de oameni.',
          date_article_5: '25 july 2024',
          title_article_6:
            'Când banii sunt mai importanți decât concediul de odihnă.',
          content_article_6:
            'Pentru ca suntem în luna august, în plină perioadă a concediilor de vară pentru cei mai mulți dintre noi, dar și pentru că în activitatea profesională, am întâlnit nu o dată, ci de multe ori, situații dintre cele mai diverse și controversate la clienții societăți comerciale în legatură cu dreptul la concediu de odihnă al angajaților, subiectul articolului de față tratează dreptul la concediu de odihnă al salariaților, dar nu din prisma obligației angajatorului de a acorda acest drept, ci de la polul opus, și anume din prisma exercitării acestui drept de către salariați. ',
          date_article_6: '12 august 2024',
          title_article_7:
            'O altfel de pregătire pentru începerea școlii pentru elevi, părinți și profesori',
          content_article_7:
            'La fel cum în prima zi de muncă, oricărui angajat i se înmânează Regulamentul intern privind organizarea și funcționarea muncii în cadrul angajatorului pentru ca acesta să cunoască de la bun început care sunt regulile de disciplină la locul de muncă, care îi sunt drepturile și care îi sunt și obligațiile în timpul serviciului, tot astfel și elevii, și profesorii, dar și părinții ar trebui să înceapă pregătirea pentru noul an școlar, ce va începe peste numai câteva zile, cu lecturarea STATUTULUI ELEVULUI, pentru a cunoaște de la bun început care sunt noile reguli la școală, care sunt drepturile, dar foarte important, și care sunt obligațiile elevilor, dar și ale profesorilor în noul an școlar 2024 - 2025',
          date_article_7: '03 september 2024',
          source: 'Source',
        },
      },
      ro: {
        translation: {
          navbar_btn_home: 'ACASĂ',
          navbar_btn_who_is: 'CINE ESTE CAMELIA PAU?',
          navbar_btn_services: 'ÎN CE DOMENII ESTE SPECIALIZATĂ?',
          navbar_btn_blog: 'BLOG',
          title: 'SERVICII DE AVOCATURĂ DE CALITATE ',
          subtitle: 'CABINET DE AVOCAT',
          characteristics_card_efficiency: 'Eficiență',
          characteristics_card_efficiency_content:
            'Acordăm servicii juridice cu eficiență și productivitate. Prioritare sunt nevoile și interesele clienților, iar soluțiile juridice sunt furnizate prompt după ce acestea au fost identificate.',
          characteristics_card_professionalism: 'Profesionalism',
          characteristics_card_professionalism_content:
            'Abordăm fiecare caz pe baza unei analize exhaustive a situațiilor legale, privind cazul din diverse unghiuri, astfel încât să fim în măsură să prevedem orice posibilă consecință juridică.',
          characteristics_card_trust: 'Încredere',
          characteristics_card_trust_content:
            'Orice relație profesională necesită sinceritate și transparență pentru a construi încrederea reciprocă. Aceste aspecte sunt esențiale pentru succesul și stabilitatea relațiilor profesionale.',
          title_services_section:
            'ÎN CE DOMENII ESTE SPECIALIZATĂ CAMELIA PAU?',
          content_services_section:
            ' Portofoliul de competențe al avocatului principal din cadrul cabinetului PAU CAMELIA cuprinde multiple arii distincte de practică și specializare, cu un accent pronunțat în domeniul achizițiilor publice, dar și în domeniul relațiilor de muncă și resurse umane, dobândind competențe suplimentare în aceste arii. Astfel, în anul 2018, a devenit și expert achiziții publice, iar în anul 2023, și-a aprofundat cunoștințele în domeniul resurselor umane, absolvind și un curs de referent resurse umane organizat de Inspectoratul Teritorial de Muncă Timiș.',
          content_services_section_paragraph_1:
            'Portofoliul de competențe al avocatului principal din cadrul cabinetului PAU CAMELIA cuprinde multiple arii distincte de practică și specializare, cu un accent pronunțat în domeniul achizițiilor publice, dar și în domeniul relațiilor de muncă și resurse umane, dobândind competențe suplimentare în aceste arii. Astfel, în anul 2018, a devenit și expert achiziții publice, iar în anul 2023, și-a aprofundat cunoștințele în domeniul resurselor umane, absolvind și un curs de referent resurse umane organizat de Inspectoratul Teritorial de Muncă Timiș.',
          content_services_section_paragraph_2:
            'Pau Camelia se individualizează prin perfecționarea sa profesională continuă, prin cunoștințele sale specializate și abilitățile relevante în domeniul dreptului muncii și al achizițiilor publice, contribuind cu o perspectivă de expert în aceste domenii și furnizând sprijin esențial clienților săi în toate aspectele complexe legate de aceste domenii.',
          button_learn_more: ' Descoperă mai mult',
          card_coporate_law_content:
            'Dreptul corporativ se referă la regulile, precum structura de guvernare, drepturile acționarilor, responsabilitățile conducerii corporației, funcționarea și conducerea corporațiilor. Este esențial pentru asigurarea unei guvernări eficiente și echitabile a corporațiilor.',
          card_coporate_law_title: 'Dreptul corporativ',
          card_commercial_law_content:
            'Dreptul comercial se concentrează pe regulile și reglementările care guvernează activitățile de afaceri.  Este esențial pentru menținerea unui mediu de afaceri echitabil și funcțional, protejând atât interesele întreprinderilor, cât și ale consumatorilor.',
          card_commercial_law_title: 'Dreptul comercial',
          card_labour_law_content:
            'Dreptul muncii reprezintă acea ramură din sistemul de drept, care reglementează sfera raporturilor de muncă. Prin raporturi de muncă se înţeleg acele relaţii stabilite cu ocazia încheierii, executării, modificării şi încetării contractului individual de muncă.',
          card_labour_law_title: 'Dreptul muncii',
          card_public_procurement_law_content:
            'Suma tuturor proceselor de planificare, stabilire a priorităților, organizare, publicitate și de proceduri, în vederea realizării de cumpărări de către organizațiile care sunt finanțate total sau parțial de bugete publice (europene, naționale centrale sau locale, donori internaționali).',
          card_public_procurement_law_title: 'Achiziții publice',
          card_tax_law_content:
            'Dreptul fiscal, ca ramură a dreptului public, este format din totalitatea actelor normative ce reglementează raporturile juridice fiscale care se nasc, se modifică şi se sting în procesul colectării impozitelor şi taxelor de la contribuabili.',
          card_tax_law_title: 'Dreptul fiscal',
          card_civil_law_content:
            'Dreptul civil este acea ramură de drept ce reglementează relațiile social-patrimoniale și nepatrimoniale stabilite între persoane fizice și/sau juridice ce se află pe poziții de egalitate juridică, chiar când una dintre părți este statul (ca persoană privată și nu ca autoritate publică).',
          card_civil_law_title: 'Dreptul civil',
          card_administrative_litigation_content:
            'Activitatea de soluţionare, cu putere de adevăr legal, de către instanţele de contencios administrativ, competente potrivit legii a conflictelor juridice în care cel puţin una din părţi este un serviciu public administrativ, iar conflictul juridic s-a născut din adoptarea sau emiterea unui act administrativ de autoritate ori din refuzul de a rezolva o cerere referitoare la un drept recunoscut de lege.',
          card_administrative_litigation_title: 'Contencios administrativ',
          card_tort_law_content:
            'Dreptul contravenţional poate fi definit ca un ansamblu de norme juridice de drept public, care reglementează relaţiile sociale cu privire la contravenţia şi contravenţionalitatea, care au drept scop protecţia juridică a unor valori sociale stabilite de legislaţia contravenţională.',
          card_tort_law_title: 'Dreptul contravențional',
          title_career_section: 'CINE ESTE PAU CAMELIA?',
          content_career_section_1:
            'Pau Camelia - Sabina – avocat titular al Pau Camelia – Cabinet de avocat a absolvit Facultatea de Drept din cadrul Universității de Vest Timișoara în anul 2005 și a urmat apoi studiile universitare de masterat în cadrul Universității de Vest Timișoara – în Drept Comunitar pe care le-a absolvit în anul 2007.',
          content_career_section_2:
            'Și-a început activitatea profesională în domeniul dreptului încă din timpul studiilor de master, respectiv din anul 2006, ca și consultant juridic în cadrul unui cabinet de avocatură.',
          content_career_section_3:
            'A susținut și a promovat examenul de intrare în profesia de avocat în anul 2009, fiind avocat înscris în Baroul Timiș începând cu anul 2009.',
          content_career_section_4:
            'În cei 14 ani de activitate ca și avocat, a practicat și s-a specializat în avocatura de afaceri, ocupându-se intens de sprijinirea și acordarea de consultanță juridică specializată în mod preponderent persoanelor juridice (societăți, instituții publice, asociații, fundații) încă de la înființarea acestora, dar și pe parcursul activității lor, uneori până la radiere și lichidare.',
          button_read_more: 'Citește mai mult',
          title_contact_section: 'BIROUL DE AVOCATURĂ PAU CAMELIA',
          subtitle_contact_section: 'DATE CONTACT',
          phone: 'TELEFON',
          mail_adress: 'ADRESĂ DE EMAIL',
          adress: 'ADRESĂ',
          subtitle_form_contact: 'CABINET DE AVOCAT',
          placeholder_form_name: 'Nume',
          placeholder_form_email: 'Email',
          placeholder_form_phone: 'Număr de telefon',
          placeholder_form_message: 'Mesaj',
          button_form_contact: 'Trimite mesajul',
          error_message_name: 'Mesajul trebuie să conțină cel puțin 5 litere.',
          error_message_phone: 'Numărul de telefon nu este corect.',
          error_message_email: 'Adresa de email nu este corectă.',
          error_message: 'Mesajul trebuie să conțină  cel puțin 10 litere.',
          succes_message_emailJs: 'Mesajul a fost transmis cu succes.',
          error_message_emailJs: 'A apărut o eroare, te rog mai încearcă.',
          title_testimonial_section: ' PĂREREA CLIENȚILOR CONTEAZĂ!',
          review_elstad:
            'Colaborez cu succes și de foarte mult timp cu Cabinetul de avocat Pau Camelia. De fiecare dată când soluțiile de recuperare au avut o portiță de reușită, doamna avocat Pau Camelia a reușit să o fructifice spre câștigul nostru, pe cale legala. Recomand cu căldură acest cabinet în activitatea de recuperare creanțe și întocmirea contractelor care ne-au ferit pe cât posibil de situații nedorite. Mulțumesc pentru suportul juridic acordat cu mult profesionalism.',
          review_eei:
            'Recomand serviciile cabinetului cu cea mai mare plăcere! Pentru fiecare situație doamna avocat Camelia Pau a apreciat corect calea de urmat; am fost de fiecare dată surprins de cât de limpede a prezentat argumentele noastre; a tratat cu maximă atenție fiecare lucrare astfel încât am avut întotdeauna succes! Cabinetul ne asigură serviciile juridice necesare  în activitatea curentă a societății, dar și în domeniul achizițiilor publice pentru participarea noastră cu succes la licitații publice. De fiecare dată serviciile prestate de cabinet ne-au adus cel mai bun rezultat posibil.',
          review_trigo:
            'Cabinetul de avocat Pau Camelia a devenit pentru noi un partener de încredere cu care colaborăm foarte bine de câțiva ani. Cabinetul ne-a reprezentat cu succes în fața Curții de Arbitraj, în litigiile cu partenerii străini, dar și în fața instanțelor de judecată din România, în litigiile cu partenerii din țară și ne acordă sprijin juridic și consultanța necesară în activitatea curentă a societății, dar și informarea legislativă pentru a fi la zi cu toate modificările legislative care apar și care au impact asupra activității societății noastre.',
          review_plan_net:
            'Ne exprimăm mulțumirea privind suportul juridic oferit de cabinetul de avocat Pau Camelia pentru că, de fiecare dată, solicitările noastre de servicii juridice pentru orice modificări societare la Registrul Comerțului, pentru redactarea de contracte și alte acte juridice și de consiliere juridică, au fost onorate cu promptitudine și profesionalism, iar înțelegerile financiare privind onorariile convenite au fost întotdeauna respectate.',
          review_papasam:
            'Mulțumim Cabinetului de avocat Pau Camelia pentru că ne-a fost alături în activitatea noastră în domeniul HoReCa, că a fost alături de noi și în perioadele dificile cu care ne-am confruntat în timpul pandemiei Covid când au fost necesare negocieri comerciale cu partenerii noștri, negocieri la care ne-am bazat pe sfaturile bune oferite de avocatul nostru. Pau Camelia - Cabinet de avocat ne-a sprijinit, de asemenea, cu operațiunile de modificare și de prelungire a mărcilor pe care le deținem și ne-a reprezentat cu succes în fața OSIM.',
          review_emiliaszuszmann:
            'Colaborarea cu cabinetul de avocatură Camelia Pau este una ce depășește deceniul. Aceasta întrucât este o colaborare promptă, care a răspuns rapid tot timpul nevoilor asociației în educație pe care o gestionez. Legile și regulile s-au schimbat des în anii de când conduc asociația, dar am găsit tot timpul siguranța unei consilieri informate și cercetate când a fost cazul. Recomand Cabinetul de Avocatură Camelia Pau pentru cei care doresc o colaborare de cursă lungă bazată pe foarte mult fairplay, transparență în comunicare și profesionalism.',
          review_pensiuneajojo:
            'Recomand cu toată încrederea serviciile cabinetului pentru că am avut parte de profesionalism, seriozitate si promptitudine de fiecare data când am apelat. Începând de la constituire societate, consultanta si reprezentare am beneficiat de fiecare data de o echipa loiala și dedicată, mulțumim pentru sprijinul acordat și pentru sufletul pe care-l depuneți in rezolvarea tuturor situațiilor!',
          review_swiss_house:
            'Cu cabinetul de avocatură Camelia Pau avem o colaborare excepțională de peste 3 ani de zile. Cabinetul ne oferă servicii juridice și consultanță pentru afacerea noastră  de o calitate foarte bună, servicii juridice privind reprezentarea societății în diverse litigii, dar și asistență juridică în legătură cu contractele comerciale.',
          review_consorhogar:
            'De fiecare dată când am întâmpinat sau întâmpinăm vreo problemă juridică apelăm cu încredere la cabinetul de avocat Pau Camelia pentru că avem siguranța că interesele societății noastre sunt bine apărate în fiecare caz în parte.  Cabinetul de avocat Pau Camelia ne-a apărat cu succes în litigiile pe care le-am avut cu autoritățile în domeniul protecției consumatorilor și nu numai; în același timp ne asigură constant consultanța juridică de care avem nevoie în activitatea noastră de construcții și dezvoltare imobiliară.',
          review_boobielove:
            'Cabinetul de avocat Pau Camelia ne-a fost alături de la bun început de când am pus bazele Asociației Moving On Breast Cancer, ne-a ajutat cu înființarea asociației și ne-a sprijinit apoi în activitatea asociației noastre. Apreciem foarte mult faptul că pe lângă susținerea juridică de care beneficiem de fiecare dată când avem nevoie de servicii juridice în activitatea asociației, simțim că avem și susținere umană și empatie din partea doamnei avocat Pau Camelia.',
          footer_title_services: 'DOMENII DE PRACTICĂ',
          footer_title_about: 'DESPRE',
          footer_title_blog: 'BLOG',
          title_timeline_career_page: 'CUM A ÎNCEPUT?',
          content_timeline_1:
            ' A fost admisă la Facultatea de Drept din cadrul Universității de Vest Timișoara.',
          content_timeline_2:
            ' A absolvit Facultatea de Drept, fiind licențiată în Drept și a început studiile de master în Drept Comunitar în cadrul Universității de Vest Timișoara.',
          content_timeline_3:
            ' S-a angajat ca și consultant juridic în cadrul unui cabinet de avocatură.',
          content_timeline_4:
            ' A finalizat studiile postuniversitare și a obținut diplomă de masterat în Drept Comunitar în cadrul Universității de Vest Timișoara.',
          content_timeline_5:
            ' A susținut și a promovat examenul de intrare în profesia de avocat, fiind avocat înscris în Baroul Timiș.',
          content_timeline_6:
            ' În prezent, își urmează vocația, aducând societății un viitor luminos, marcat de justiție și echitate.',
          title_career_page: 'ACTIVITATEA ÎN DOMENIUL DREPTULUI',
          paragraph_career_page_1_1: ' În cei',
          paragraph_career_page_1_2:
            'ani de activitate ca și avocat, a practicat și s-a specializat în avocatura de afaceri, ocupându-se intens de sprijinirea și acordarea de consultanță juridică specializată în mod preponderent persoanelor juridice (societăți, instituții publice, asociații, fundații) încă de la înființarea acestora, dar și pe parcursul activității lor, uneori până la radiere și lichidare.',
          paragraph_career_page_2:
            ' Deține și calitatea de expert achiziții publice, fiind specializată în domeniul achizițiilor publice și acordând  consultanța juridică necesară în vederea participării la licitațiile publice, precum și de reprezentare juridică în fața autorităților contractante, dar și în fața Consiliului Național de  Soluționare a Contestațiilor, precum și a instanțelor de judecată, în litigiile născute în urma participării clienților la licitații publice.',
          paragraph_career_page_3:
            ' În activitatea profesională, respectă cu strictețe jurământul depus la intrarea în profesie și se distinge ca și avocat: prin profesionalismul și răbdarea pe care le manifestă față de client, dar și prin buna comunicare și relaționare cu clientul prin conștiinciozitate și responsabilitate față de munca de avocat pe care o practică cu pasiune, dar și prin bucuria pe care o resimte în a-i sprijini pe ceilalți în apărarea libertăților, drepturilor şi intereselor lor legitime.',
          title_circle_container: 'Calități',
          content_circle_container_1: 'Profesionalism',
          content_circle_container_2: 'Răbdare',
          content_circle_container_3: 'Bună comunicare',
          content_circle_container_4: 'Responsabilitate',
          service_page_paragraph_1:
            'Avocatul titular Pau Camelia a asistat și a reprezentat numeroși clienți, persoane juridice din România, dar și multe societăți din alte state membre, în diferite proceduri comerciale, administrative și jurisdicționale.',
          service_page_paragraph_2:
            'Astfel, deține experiență importantă și diversă în domeniul dreptului comercial și dreptului corporativ, dreptului civil, contencios administrativ si fiscal, dreptului muncii, dreptului contravențional, achiziții publice, putând asigura consultanță juridică de specialitate la cel mai înalt nivel, asistență juridică și reprezentare judiciară și extrajudiciară în domeniile de expertiză indicate și nu numai.',
          service_page_paragraph_3:
            'Prin urmare, dacă întâmpinați orice fel de probleme cu angajații sau pur și simplu vreți să implementați noi proceduri în societate sau să încheiați contractul colectiv de muncă la nivelul unității ori regulament intern, dacă vreți să participați la licitații publice ivite în domeniul dumneavoastră de activitate, dacă aveți de recuperat creanțe, dacă vreți să încheiați sau să negociați contracte cu furnizorii sau clienții firmei, dacă aveți de efectuat orice fel de modificări statutare în cadrul firmei, dacă vreți să intrați într-o nouă afacere sau să ieșiți dintr-o afacere în care vă aflați deja, dacă vreți să înregistrați o marcă, dacă aveți de obținut autorizații, avize sau documente legale de la autorități, dacă sunteți dat în judecată sau vrei să chemați pe cineva în judecată, precum și în orice alt context juridic care poate interveni în activitatea dumneavoastră de afaceri și nu numai, Pau Camelia - Cabinet de avocat vă poate oferi consultanța juridică, asistența și reprezentarea de care aveți nevoie pentru a depăși cât mai bine și mai eficient din punct de vedere juridic situația cu care vă confruntați.',
          title_accordion:
            'Serviciile prestate de cabinetul de avocat PAU CAMELIA',

          item_accordion_consultations: 'Consultaţii și alte servicii juridice',
          panel_accordion_consultations_1:
            'Suntem aici pentru a vă oferi o gamă completă de servicii juridice, adaptate nevoilor dumneavoastră. Serviciile noastre includ:',
          title_list_item_consultations_1: 'Consultații juridice:',
          list_item_consultations_1:
            'Oferim servicii de consultanță juridică prin diverse modalități convenabile pentru clienții noștri. Aceste modalități includ consultații la distanță prin corespondență electronică, întâlniri la sediul cabinetului nostru de avocat, discuții la sediul clienților sau în orice altă locație solicitată de către client.',
          title_list_item_consultations_2: 'Alte servicii juridice specifice:',
          list_item_consultations_2:
            'Acoperim o varietate largă de servicii specifice profesiei de avocat. Acestea pot include reprezentare în fața instanței, redactare și analiză de documente, soluționarea disputelor, asistență în negocieri și multe altele.',
          panel_accordion_consultations_2:
            'Toate serviciile juridice pot fi prestate și de la distanță, prin corespondență electronică, inclusiv semnarea contractului de asistență juridică se poate realiza electronic, avocatul Pau Camelia deținând semnătură electronică extinsă și putând astfel transmite pe cale electronică orice fel de cereri și/sau acțiuni judiciare, în numele și pe seama clienților, oriunde s-ar afla sediul și/sau domiciliul acestora, în România sau în străinătate.',
          panel_accordion_consultations_3:
            'Indiferent de necesitățile dumneavoastră, suntem pregătiți să vă oferim expertiză și sprijin în toate aspectele legale, astfel încât să puteți lua decizii informate pentru a vă apăra drepturile și a vă proteja interesele.',

          item_accordion_support:
            'Servicii de asistență și/sau reprezentare a clientului',
          title_list_item_support_1:
            'Asistență și/sau reprezentare în fața instanțelor de judecată: ',
          list_item_support_1:
            'Vă oferim servicii de reprezentare în fața oricăror instanțe judiciare din țară, de la judecătorii și tribunale la curțile de apel, Înalta Curte de Casație și Justiție și Curtea Constituțională. Ne asigurăm că drepturile și interesele dumneavoastră sunt apărate cu fermitate și competență în cadrul proceselor judiciare.',
          title_list_item_support_2:
            'Asistență și/sau reprezentare în fața instanțelor arbitrale: ',
          list_item_support_2:
            'Vă oferim servicii de reprezentare în fața instanțelor arbitrale. Aceasta include toate aspectele legate de procedurile de arbitraj, asigurându-vă că sunteți reprezentat în mod corespunzător în fața curților de arbitraj din România.',
          title_list_item_support_3:
            'Asistență și/sau reprezentare în procedurile de executare silită: ',
          list_item_support_3:
            'Vă ghidăm în procesul de executare silită a titlurilor executorii, pregătim în numele și pe seama dumneavoastră cererile de executare silită și vă asigurăm asistența și reprezentarea juridică necesară, precum și interacțiunea cu executorii judecătorești pentru a vă proteja interesele în faza de executare silită, fie că sunteți creditor, debitor, terț garant sau dețineți orice alte calitate în procedura executării silite.',
          title_list_item_support_4:
            'Asistență și/sau reprezentare în fața autorităților publice: ',
          list_item_support_4:
            'Oferim reprezentare juridică în relația cu diverse autorități și instituții publice. Scopul nostru este să ne asigurăm că drepturile dumneavoastră sunt respectate și că interacțiunile cu aceste entități sunt gestionate eficient și legal.',
          title_list_item_support_5:
            'Asistență și/sau reprezentare pentru recuperarea creanțelor și în procedura ordonanței de plată: ',
          list_item_support_5:
            'Furnizăm asistență în procesul de recuperare a creanțelor, inclusiv redactarea și expedierea către debitorii dumneavoastră a somațiilor de plată, dar și elaborarea cererilor de ordonanță de plată pentru a obține recuperarea eficientă a datoriilor.',
          title_list_item_support_6:
            'Asistența și reprezentare în procedura insolvenței: ',
          list_item_support_6:
            'Vă susținem în procedurile de insolvență, inclusiv prin redactarea cererilor de deschidere a procedurii, cererilor de înscriere la masa credală, cererilor de atragere a răspunderii administratorului, contestațiilor împotriva măsurilor și rapoartelor administratorului/lichidatorului judiciar, precum și orice alte cereri și/sau acțiune în materie de insolvență/faliment.',

          item_accordion_establishment:
            'Înființarea și administrarea entităților juridice',
          panel_accordion_establishment_1:
            'Suntem aici pentru a vă oferi o gamă completă de servicii legate de înființarea și administrarea entităților juridice în Timișoara și în județul Timiș, inclusiv în Giroc. Serviciile oferite acoperă:',
          title_list_item_establishment_1:
            'Înființarea diverselor tipuri de entități juridice: ',
          list_item_establishment_1:
            'De la societăți comerciale până la persoane fizice autorizate, vă susținem în procesul de înființare, asigurându-ne că toate documentele și procedurile sunt conforme cu cerințele legale.',
          title_list_item_establishment_2:
            'Modificări la entitățile deja înregistrate: ',
          list_item_establishment_2:
            'Dacă aveți nevoie de modificări în structura sau forma entităților deja înregistrate, suntem aici pentru a vă ghida prin acest proces și a vă asigura că toate aspectele legale sunt respectate.',
          title_list_item_establishment_3:
            'Deschidere/închidere de sucursale, filiale și puncte de lucru: ',
          list_item_establishment_3:
            'Vă oferim asistență pentru deschiderea și/sau închiderea acestor entități secundare, asigurându-vă că totul se întâmplă rapid, eficient și conform cu reglementările legale.',
          title_list_item_establishment_4:
            'Operațiuni de dizolvare și lichidare: ',
          list_item_establishment_4:
            'Dacă aveți nevoie să dizolvați sau să lichidați o persoană juridică, vă oferim ghidare și asistență în procesul respectiv, asigurându-ne că totul se desfășoară legal și corespunzător.',
          title_list_item_establishment_5:
            'Reprezentarea în fața Registrului Comerțului și Ministerul Justiției: ',
          list_item_establishment_5:
            'Vă reprezentăm în relația cu autoritățile corespunzătoare, asigurându-ne că toate cerințele sunt îndeplinite și că interesele dumneavoastră sunt protejate.',
          panel_accordion_establishment_2:
            'De asemenea, vă putem asigura, la sediul profesional al avocatului, stabilirea sediului social temporar pentru societățile în curs de constituire sau care își mută sediul social în Timișoara sau în județul Timiș (Giroc) ori își stabilesc un sediu secundar al societăţii în Timișoara sau în județul Timiș (Giroc). Aceasta vă va permite să inițiați sau să continuați activitățile în mod eficient și în conformitate cu cerințele legale.',

          item_accordion_summations:
            'Întocmirea de notificări, somaţii, adrese, cereri',
          panel_accordion_summations_1:
            'Oferim servicii complete pentru întocmirea diverselor tipuri de documente juridice, inclusiv:',
          title_list_item_summations_1: 'Notificări: ',
          list_item_summations_1:
            'Vă ajutăm la întocmirea notificărilor legale pentru a comunica informații importante sau solicitări către orice fel de persoane, fizice sau juridice.',
          title_list_item_summations_2: 'Somații: ',
          list_item_summations_2:
            'Oferim servicii de redactare a somațiilor pentru a atrage atenția asupra unor obligații sau măsuri legale care trebuie luate de către destinatari.',
          title_list_item_summations_3: 'Adrese: ',
          list_item_summations_3:
            'Vă putem asista în redactarea de adrese oficiale sau comunicări scrise, fie că este vorba de corespondență cu autoritățile, instituțiile publice sau cu alte părți implicate.',
          title_list_item_summations_4: 'Cereri: ',
          list_item_summations_4:
            'Suntem aici pentru a vă ajuta în elaborarea diverselor tipuri de cereri.',
          panel_accordion_summations_2:
            'Fie că aveți nevoie de documente pentru comunicare oficială, pentru a formula cereri sau pentru a solicita acțiuni legale specifice, vă putem sprijini în întocmirea acestor documente în mod corespunzător și profesional.',

          item_accordion_trademark: 'Servicii complete în domeniul mărcilor',
          title_list_item_trademark_1: 'Înregistrarea cererilor de marcă: ',
          list_item_trademark_1:
            'Vă ajutăm în procesul de înregistrare a cererilor de marcă, asigurându-ne că documentele sunt complete și conforme cu cerințele legale.',
          title_list_item_trademark_2:
            'Modificări ale mărcilor deja înregistrate: ',
          list_item_trademark_2:
            'Dacă aveți mărci deja înregistrate și aveți nevoie de modificări, suntem aici pentru a vă ghida în procesul de actualizare a acestora conform necesităților dumneavoastră.',
          title_list_item_trademark_3:
            'Reprezentare în fața Oficiului de Stat pentru Invenții și Mărci (OSIM): ',
          list_item_trademark_3:
            'Vă reprezentăm în relația cu OSIM, asigurându-ne că interesele dumneavoastră sunt protejate și susținute în toate aspectele legate de cererile de marcă.',
          title_list_item_trademark_4:
            'Opoziții/contestații la înregistrarea mărcilor: ',
          list_item_trademark_4:
            'Dacă sunteți în situația de a contesta sau de a depune opoziții în legătură cu înregistrarea altor mărci, vă putem reprezenta și vă putem susține interesele în acest proces.',

          item_accordion_negotiation:
            'Redactarea, analiza și negocierea contractelor',
          panel_accordion_negotiation_1:
            'Oferim servicii specializate de redactare, analiză și asistență a contractelor, precum și sprijin în procesul de negociere a acestora. Suntem pregătiți să vă ajutăm în toate etapele legate de contracte, inclusiv: ',
          title_list_item_negotiation_1: 'Redactarea contractelor: ',
          list_item_negotiation_1:
            'Putem elabora contracte pe baza specificațiilor și cerințelor dumneavoastră, asigurându-ne că termenii și condițiile sunt clar exprimate și respectă cerințele legale.',
          title_list_item_negotiation_2: 'Analiza contractelor: ',
          list_item_negotiation_2:
            'Efectuăm analize detaliate ale contractelor existente pentru a identifica aspectele cheie, riscurile și implicațiile legale, asigurându-ne că sunteți bine informat înainte de a lua o decizie și bine protejat contractual.',
          title_list_item_negotiation_3:
            'Asistența la negocierea contractelor: ',
          list_item_negotiation_3:
            'Vă oferim sprijin în procesul de negociere a contractelor, asigurându-ne că interesele dumneavoastră sunt protejate și că obiectivele dumneavoastră sunt atinse în cadrul negocierilor.',
          panel_accordion_negotiation_2:
            'Indiferent dacă aveți nevoie de un contract nou, de acte adiționale la contractele existente, de analiza unor propuneri contractuale venite din partea partenerilor de afaceri sau de asistență la negocierea contractelor, suntem aici pentru a vă oferi expertiză și soluții legale eficiente.',

          item_accordion_labor_law:
            'Servicii specifice în domeniul dreptului muncii',
          panel_accordion_labor_law_1:
            'Oferim servicii specializate în sfera dreptului muncii, atât pentru angajatori, cât și pentru angajați. Iată modurile în care vă putem sprijini: ',
          title_list_item_labor_law_1:
            'Redactarea actelor specifice dreptului muncii: ',
          list_item_labor_law_1:
            'Ne ocupăm de redactarea contractelor individuale de muncă, a actelor de modificare a contractelor de muncă, a clauzelor speciale ale contractului individual de muncă, a actelor adiționale privind formarea profesională, a documentelor privind cercetarea disciplinară, a actelor necesare în caz de concediere colectivă, a oricăror decizii ale angajatorului, precum și a altor documente relevante pentru a asigura respectarea legii în relațiile de muncă.',
          title_list_item_labor_law_2:
            'Asistență în procedura de negociere, încheiere și înregistrare a contractelor colective de muncă, precum și de elaborare a regulamentelor interne:  ',
          list_item_labor_law_2:
            'Vă susținem în procesul de negociere și redactare a contractelor colective de muncă și regulamentelor interne, asigurându-vă că acestea respectă cerințele legale și reflectă nevoile organizației.',
          title_list_item_labor_law_3:
            'Reprezentarea în fața Inspectoratului Teritorial de Muncă: ',
          list_item_labor_law_3:
            'Vă reprezentăm în situațiile de interacțiune cu Inspectoratul Teritorial de Muncă, indiferent de tipul de inspecție sau control, în mod competent. Scopul nostru este să ne asigurăm că drepturile vă sunt protejate și interesele vă sunt apărate în conformitate cu legislația muncii.',
          panel_accordion_labor_law_2:
            'Indiferent de poziția dumneavoastră de angajator sau de angajat, vă putem asigura consilierea juridică de cea mai înaltă calitate pentru a găsi, în fiecare caz în parte, soluția optimă de urmat în acord cu prevederile legislației muncii și cu interesul dumneavoastră.',

          item_accordion_public_acquisitions:
            'Servicii complete în ceea ce privește achizițiile publice',
          panel_accordion_public_acquisitions_1:
            'Oferim servicii specializate în sfera dreptului muncii, atât pentru angajatori, cât și pentru angajați. Iată modurile în care vă putem sprijini: ',
          title_list_item_public_acquisitions_1:
            'Consultanță juridică și reprezentare în procedurile de atribuire: ',
          list_item_public_acquisitions_1:
            'Vă oferim consultanță detaliată pe parcursul procedurilor de atribuire a contractelor de achiziție publică, asigurându-ne că toate aspectele legale sunt respectate. De asemenea, vă putem reprezenta în acest proces pentru a vă asigura că interesele sunt protejate.',
          title_list_item_public_acquisitions_2:
            'Pregătirea ofertelor pentru licitații publice:  ',
          list_item_public_acquisitions_2:
            'Vă ajutăm să pregătiți oferte competitive și conforme pentru a participa la licitații publice. Ne asigurăm că documentele sunt complete și respectă cerințele impuse.',
          title_list_item_public_acquisitions_3:
            'Reprezentare în fața Consiliului Național de Soluționare a Contestațiilor (CNSC): ',
          list_item_public_acquisitions_3:
            'În cazul contestațiilor legate de procedurile de atribuire a contractelor, vă reprezentăm în fața CNSC. Scopul nostru este să ne asigurăm că interesele dumneavoastră sunt apărate în mod adecvat și că procesul decizional este corect și legal.',
          title_list_item_public_acquisitions_4:
            'Reprezentare în fața instanțelor de judecată în litigiile privind achizițiile publice: ',
          list_item_public_acquisitions_4:
            'Vă oferim servicii de reprezentare în litigiile care au ca obiect contestarea atribuirii de contracte de achiziții publice, în procesele şi cererile privind acordarea despăgubirilor pentru repararea prejudiciilor cauzate în cadrul procedurii de atribuire, precum şi în cele privind executarea, nulitatea, anularea, rezoluțiunea, rezilierea sau denunțarea unilaterală a contractelor de achiziție publică.',
          panel_accordion_public_acquisitions_2:
            'Indiferent de poziția dumneavoastră în procedura de achiziție publică, vă suntem alături cu servicii specializate pentru a vă ghida prin toate etapele de parcurs în procedură, asigurându-ne că vă conformați cerințelor legale și că aveți cele mai bune șanse de succes în achiziția publică de interes pentru dumneavoastră.',
          published: 'Publicat',
          title_article_1:
            'O lecție de umanitate de la CEDO în cauza c. împotriva României',
          title_article_2:
            'Avocatul - ajutor neprețuit pentru client, clientul - dar neprețuit pentru avocat',
          blog_title: 'Blog & Justiție',
          blog_subtitle: 'Din dragoste pentru Dreptate și Oameni',
          blog_describe:
            'Urmărește-mă pentru a descoperi povești reale și informații proaspete din lumea dreptului.',
          blog_title_most_recent_article: 'Cel mai recent articol',
          blog_title_see_all_articles: 'Vezi toate articolele',
          content_article_1:
            'Hotărârea în Cauza C. împotriva României din 30.08.2022 nu este doar o importantă bornă de referință în ceea priveste respectarea drepturilor în cazul hărtuirii la locul de muncă, dar este și o lectie de umanitate dată statului român de către judecătorii Curții Europene a Drepturilor Omului – CEDO.',
          content_article_2: `Profesia de avocat îl are în centrul atenției pe om, ale cărui drepturi și libertăți le apără. Astfel, la înscrierea în barou, avocatul depune, în cadru solemn, următorul jurământ: "Jur să respect şi să apăr Constituţia şi legile ţării, drepturile şi libertăţile omului şi să exercit profesia de avocat cu cinste şi demnitate. Aşa să-mi ajute Dumnezeu!`,
          date_article_1: '21 iunie 2024',
          date_article_2: '24 iunie 2024',
          title_article_3: 'Firmele nu mai sunt firme, sunt brand!',
          content_article_3:
            '“Oamenii nu mai sunt oameni, sunt brand” spune un vers din melodia „Opriți planeta” - o melodie difuzată pe toate canalele. Parafrazând acest vers la lumea afacerilor, putem spune că: FIRMELE NU MAI SUNT FIRME, SUNT BRAND! ',
          date_article_3: '1 iulie 2024',
          title_article_4:
            'Să ne căutăm dreptatea în echilibru este uneori mai bine decât a ne căuta dreptatea în instanță',
          content_article_4:
            'In ultima perioadă de timp auzim vorbindu-se tot mai mult despre importanța echilibrului în viața omului! Tot mai des se propagă ideea de a găsi un echilibru în toate aspectele vieții noastre. ',
          date_article_4: '9 iulie 2024',
          title_article_5: 'Avocat cu drag de oameni!',
          content_article_5:
            'Pe cei care m-au cunoscut ca și avocat, dar și pe cei care încă nu m-au cunoscut, vă invit să citiți acest articol care este despre mine, despre avocatul Pau Camelia - Sabina, pe scurt, m-aș descrie ca fiind un avocat cu drag de oameni.',
          date_article_5: '25 iulie 2024',
          source: 'Sursă',
          title_article_6:
            'Când banii sunt mai importanți decât concediul de odihnă.',
          content_article_6:
            'Pentru ca suntem în luna august, în plină perioadă a concediilor de vară pentru cei mai mulți dintre noi, dar și pentru că în activitatea profesională, am întâlnit nu o dată, ci de multe ori, situații dintre cele mai diverse și controversate la clienții societăți comerciale în legatură cu dreptul la concediu de odihnă al angajaților, subiectul articolului de față tratează dreptul la concediu de odihnă al salariaților, dar nu din prisma obligației angajatorului de a acorda acest drept, ci de la polul opus, și anume din prisma exercitării acestui drept de către salariați. ',
          date_article_6: '12 august 2024',
          title_article_7:
            'O altfel de pregătire pentru începerea școlii pentru elevi, părinți și profesori',
          content_article_7:
            'La fel cum în prima zi de muncă, oricărui angajat i se înmânează Regulamentul intern privind organizarea și funcționarea muncii în cadrul angajatorului pentru ca acesta să cunoască de la bun început care sunt regulile de disciplină la locul de muncă, care îi sunt drepturile și care îi sunt și obligațiile în timpul serviciului, tot astfel și elevii, și profesorii, dar și părinții ar trebui să înceapă pregătirea pentru noul an școlar, ce va începe peste numai câteva zile, cu lecturarea STATUTULUI ELEVULUI, pentru a cunoaște de la bun început care sunt noile reguli la școală, care sunt drepturile, dar foarte important, și care sunt obligațiile elevilor, dar și ale profesorilor în noul an școlar 2024 - 2025',
          date_article_7: '03 septembrie 2024',
        },
      },
    },
  });
